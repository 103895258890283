import {__html} from '../constants/terms';
import {openExternalWebview} from '../scheme/web';

const onClickLink = (e: MouseEvent) => {
  e.preventDefault();
  const target = e.currentTarget as HTMLAnchorElement;

  if (!target.dataset?.url) return;

  const {url} = target.dataset;
  openExternalWebview(url, {
    isCurrentWebviewClose: false
  });
};

const addTermsEvent = () => {
  const csEl = document.querySelector<HTMLAnchorElement>('#terms_cs');
  const privacyEl = document.querySelector<HTMLAnchorElement>('#terms_privacy');

  csEl?.addEventListener('click', onClickLink);
  privacyEl?.addEventListener('click', onClickLink);
  return () => {
    csEl?.removeEventListener('click', onClickLink);
    privacyEl?.removeEventListener('click', onClickLink);
  };
};

export {addTermsEvent, __html};
