import {getAgent, getClientHints, getClientHintsSync, OS} from '@kakao/agent';

type AgentInfo = ReturnType<typeof getAgent>;

const getUserAgent = (): AgentInfo => {
  const agentInfo = getClientHintsSync();
  if (agentInfo) return agentInfo;
  return getAgent();
};

export const isValidTalkVersion = (curVer: string | undefined, minVer: string | undefined) => {
  if (!curVer || !minVer) return false;
  const [curMajor, curMinor, curPatch] = curVer.split('.').map((v) => Number(v));
  const [minMajor, minMinor, minPatch] = minVer.split('.').map((v) => Number(v));

  if (curMajor !== minMajor) return curMajor >= minMajor;
  if (curMinor !== minMinor) return curMinor >= minMinor;
  if (curPatch !== minPatch) return curPatch >= minPatch;
  return true;
};

export const isUnderTalkVersion = (curVer: string | undefined, underVer: string | undefined) => {
  if (!curVer || !underVer) return false;
  const [curMajor, curMinor, curPatch] = curVer.split('.').map((v) => Number(v));
  const [underMajor, underMinor, underPatch] = underVer.split('.').map((v) => Number(v));

  if (curMajor !== underMajor) return curMajor <= underMajor;
  if (curMinor !== underMinor) return curMinor <= underMinor;
  if (curPatch !== underPatch) return curPatch <= underPatch;
  return false;
};

// NOTE: getUserAgent 함수 내부의 getClientHintsSync를 getClientHints로 변경하고 getOsVersion 함수 제거하기
export const getOsVersion = async () => {
  // NOTE: getClientHints가 iOS Safari에서는 지원을 하지 않아 try/catch 추가
  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData
  let agentInfo: AgentInfo;
  try {
    agentInfo = await getClientHints();
  } catch {
    agentInfo = getAgent();
  }
  const {osVersion = '0.0.0'} = agentInfo;
  const [major, minor, patch] = osVersion.split('.').map((v) => Number(v));
  return {major, minor, patch};
};

export const {isDesktop, isMobile, kakaoTalkVersion, isKakaoTalk, os, deviceType, isChrome} = getUserAgent();
// getClientHints에서는 isTablet을 검증하지 못함
// https://github.daumkakao.com/webtech/agent/blob/develop/src/clientHints/toAgentInfo.ts#L61
export const {isTablet} = getAgent();
export const isAndroid = os === OS.Android;
export const isIOS = os === OS.iOS || (isTablet && os === OS.Mac);

/**
 * 태블릿 Chrome은 태블릿 인지 판단하는 것이 불가능
 * 따라서 소거법에 의해 다음과 같이 정의
 */
export const isChromeInTablet = isChrome && os !== OS.Mac && os !== OS.Windows && !isMobile;

/**
 * iPad는 isMobile: false, isDesktop: false
 * 갤럭시탭은 isMobile: false, isDekstop: true
 * 따라서 isDesktop만으로 PC라는 것을 알 수 없으므로 !isTabletOrMobile 사용 권장
 */
export const isTabletOrMobile = isTablet || isMobile || isChromeInTablet;
