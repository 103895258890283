/**
 * 마크업 스크립트 파일을 가져와 사용하는 코드로 group_empty 요소 사용 시 패딩을 계산하여 추가한다.
 * https://github.kakaocorp.com/UIProj2/student-idcard-2023/blob/0870ba724a566aebe4c9e99e72e24f00980090c1/src/js/ui.js#L77
 */
export const addPaddingOnGroupEmpty = () => {
  const areaRef: HTMLDivElement | null = document.querySelector('.content-full > .inner_g');
  const contentRef = document.querySelector(`.group_empty`);

  if (!areaRef || !contentRef) return;

  const {clientHeight: areaHeight} = areaRef;
  const {clientHeight: contentHeight} = contentRef;

  if (contentHeight >= areaHeight) {
    areaRef.style.paddingTop = '16px';
    areaRef.style.paddingBottom = '16px';
  } else {
    areaRef.style.paddingTop = `${(areaHeight - contentHeight) * 0.44}px`;
    areaRef.style.paddingBottom = `${(areaHeight - contentHeight) * 0.56}px`;
  }
};

export const addPaddingOnGroupSearch = () => {
  const groupSearch = document.getElementsByClassName('group_search');
  if (!groupSearch.length) return;

  const areaRef: HTMLDivElement | null = document.querySelector('.group_search + .group_empty');
  const contentRef = document.querySelector('.group_empty > .txt_g');

  if (!areaRef || !contentRef) return;

  const {clientHeight: areaHeight} = areaRef;
  const {clientHeight: contentHeight} = contentRef;

  areaRef.style.paddingTop = `${(areaHeight - contentHeight) * 0.3}px`;
  areaRef.style.paddingBottom = `${(areaHeight - contentHeight) * 0.7}px`;
};

/**
 * 바텀시트(하프뷰) 에서 group_empty 요소 사용 시 패딩을 계산하여 추가한다.
 * https://github.kakaocorp.com/UIProj2/wallet-2023/blob/master/src/js/ui.js#L121
 * */
export const addPaddingOnGroupEmptyWithModalBody = () => {
  let areaHeight = 0;
  let contentHeight = 0;
  let areaPaddingTop = 0;
  let areaPaddingBottom = 0;

  const groupEmpty = document.querySelector<HTMLElement>('.modal_body .group_empty');
  if (!groupEmpty) return;

  areaHeight = 164;

  const titList = document.querySelector('.modal_body .tit_list');
  const iconLoading = document.querySelector('.modal_body .group_empty > .icon_loading');
  const txtG = document.querySelector('.modal_body .group_empty > .txt_g');

  if (titList) {
    areaHeight = 298;
    contentHeight = 40;
  } else if (iconLoading) {
    contentHeight = 30;
  } else if (txtG) {
    contentHeight = txtG.clientHeight;
  }

  areaPaddingTop = (areaHeight - contentHeight) * 0.44;
  areaPaddingBottom = (areaHeight - contentHeight) * 0.56 - 16;

  groupEmpty.style.paddingTop = `${areaPaddingTop}px`;
  groupEmpty.style.paddingBottom = `${areaPaddingBottom}px`;
};
