import {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';

import {appendQueryParamToUrl, log} from './common';

export type Params = Record<string, any>;

type ApiFunction<Res = unknown> = (
  url: string,
  params?: Params,
  options?: AxiosRequestConfig
) => Promise<AxiosResponse<Res>>;

type Method = 'get' | 'post' | 'patch' | 'delete' | 'put';

class AxiosClient {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    this.addLoggingInterceptors();
  }

  public request = <Res = unknown>(method: Method): ApiFunction<Res> =>
    ((
      {
        get: this.apiGet<Res>(),
        post: this.apiPost<Res>(),
        patch: this.apiPatch<Res>(),
        put: this.apiPut<Res>(),
        delete: this.apiDelete<Res>()
      } as const
    )[method]);

  private addLoggingInterceptors() {
    const {request, response} = this.instance.interceptors;
    request.use(this.requestLogger);
    response.use(this.axiosResLogger);
  }

  private requestLogger = (config: AxiosRequestConfig) => {
    const {method, url, params} = config;
    log(`[API 요청]\n[METHOD]${method}\n[URL] ${url}\n[PARAM]`, params);
    return config;
  };

  private axiosResLogger = (response: AxiosResponse<any>) => {
    const {method, url} = response.config;
    const {status, data} = response;
    log(`[API성공]\n[STATUS] ${status}\n[METHOD] ${method}\n[URL] ${url}\n[RESPONSE]`, data);
    return response;
  };

  private apiGet<Res>(): ApiFunction<Res> {
    return (url, queryParams, options) => {
      const urlWithQueryParams = appendQueryParamToUrl(url, queryParams);
      const response = this.instance.get<Res>(urlWithQueryParams, options);
      return response;
    };
  }

  private apiPost<Res>(): ApiFunction<Res> {
    return (url, body, options) => {
      const response = this.instance.post<Res>(url, body, options);
      return response;
    };
  }

  private apiPut<Res>(): ApiFunction<Res> {
    return (url, body?, options?) => {
      const response = this.instance.put<Res>(url, body, options);
      return response;
    };
  }

  private apiPatch<Res>(): ApiFunction<Res> {
    return (url, body, options) => {
      const response = this.instance.patch<Res>(url, body, options);
      return response;
    };
  }

  private apiDelete<Res>(): ApiFunction<Res> {
    return (url, queryParams, options) => {
      const urlWithQueryParams = appendQueryParamToUrl(url, queryParams);
      const response = this.instance.delete<Res>(urlWithQueryParams, options);
      return response;
    };
  }
}

export const getApiClient = (instance: AxiosInstance) => {
  const axiosClient = new AxiosClient(instance);

  const api = async <Res = unknown>(
    method: Method,
    url: string,
    params?: Params,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<Res>> => {
    const result = await axiosClient.request<Res>(method)(url, params, options);

    return result;
  };

  return api;
};
