import {normal} from 'color-blend';
import {RGBA} from 'color-blend/dist/types';

const parseRGBA = (rgba: string): RGBA => {
  const result = rgba.match(/\d+/g);
  if (result) {
    const [r, g, b, a = 1] = result;
    return {r: +r, g: +g, b: +b, a: +a};
  }

  throw new Error(`Invalid rgba color format: ${rgba}`);
};

const isHex = (code: string) => {
  return /^#(([\dA-Fa-f]{3}){1,2}|([\dA-Fa-f]{4}){1,2})$/.test(code);
};

const hex2rgba = (hex: string): RGBA => {
  let c: string[] = hex.substring(1).split('');

  if (c.length === 3) c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], 'ff'];
  if (c.length === 4) c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], c[3] + c[3]];
  if (c.length === 6) c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], 'ff'];
  if (c.length === 8) c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], c[6] + c[7]];

  c = c.map((char) => parseInt(char, 16).toString());
  c[3] = (Math.round((parseInt(c[3], 10) / 255) * 100) / 100).toString();
  return {r: +c[0], g: +c[1], b: +c[2], a: +c[3]};
};

const rgb2hex = (rgba: RGBA) => {
  // 알파값은 사용하지 않는다.
  const {r, g, b} = rgba;
  const red = r.toString(16).padStart(2, '0');
  const green = g.toString(16).padStart(2, '0');
  const blue = b.toString(16).padStart(2, '0');

  return `#${red}${green}${blue}`;
};

export const blendColor = (origin: string | RGBA, target: string | RGBA): string => {
  const originRgba = typeof origin === 'string' ? (isHex(origin) ? hex2rgba(origin) : parseRGBA(origin)) : origin;
  const targetRgba = typeof target === 'string' ? (isHex(target) ? hex2rgba(target) : parseRGBA(target)) : target;

  return rgb2hex(normal(originRgba, targetRgba));
};
