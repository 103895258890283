import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';

import {log} from './common';

type axiosInterceptorFn<T> = (config: T) => T;

export const interceptorPipe =
  <T>(...func: axiosInterceptorFn<T>[]) =>
  (input: T) =>
    func.reduce((prev, fn) => fn(prev), input);

export const requestLogger = (config: AxiosRequestConfig) => {
  const {method, url, params} = config;
  log(`[API 요청]\n[METHOD]${method}\n[URL] ${url}\n[PARAM]`, params);
  return config;
};
export const axiosResLogger = (response: AxiosResponse<any>) => {
  const {method, url} = response.config;
  const {status, data} = response;

  log(`[API성공]\n[STATUS] ${status}\n[METHOD] ${method}\n[URL] ${url}\n[RESPONSE]`, data);
  return response;
};

export const logAxiosError = (error: AxiosError) => {
  if (!error.config && axios.isCancel(error)) {
    log(`[API취소] ${error.message}`);
    return;
  }

  const {method, url} = error.config ?? {};

  if (error.response) {
    const {status, data} = error.response;
    log(`[API실패]\n[STATUS] ${status}\n[METHOD] ${method}\n[URL] ${url}\n[RESPONSE]`, data);
    return;
  }

  log(`[API실패]\n[METHOD] ${method} \n[URL] ${url} ${error}`);
};
