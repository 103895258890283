import {PHASE} from '@zzng/common/constants/phase';

import {isIOS} from './agent';
import {Params} from './api';

export const isLocal = TARGET === PHASE.LOCAL;
export const isDev = [PHASE.LOCAL, PHASE.SANDBOX].includes(TARGET);
export const isCbt = [PHASE.CBT, PHASE.BETA].includes(TARGET);
export const isReal = TARGET === PHASE.REAL;

export const log = (...messages: any[]) => {
  if (!isDev) return;

  const filteredMessages = messages.filter(Boolean);

  if (isIOS) {
    const parsedMessage = filteredMessages.reduce((prev, msg) => {
      const parseMsg = typeof msg === 'object' ? JSON.stringify(msg) : msg;
      return prev + parseMsg;
    }, '');
    console.log(parsedMessage);
    return;
  }

  console.log(...filteredMessages);
};

export const debug = (...msg: any[]) => {
  if (!isDev) return;
  log('DEBUG', ...msg);
};

export const parseQuery = (url?: string) => {
  const {searchParams} = new URL(url || location.href, location.origin);
  const result: {[key: string]: string} = {};
  searchParams.forEach((value, key) => {
    result[key] = value;
  });

  return result;
};

export const appendQueryParamToUrl = (url: string, queryParams?: Params) => {
  const newUrl = new URL(url, location.origin);
  Object.entries(queryParams || {})
    .filter(([, value]) => value !== undefined && value !== null)
    .forEach(([key, value]) => newUrl.searchParams.append(key, value));
  return newUrl.href;
};

export const closePage = (closeType: 'close' | 'back') => {
  if (closeType === 'close') {
    location.href = 'kakaotalk://web/close';
  } else {
    history.back();
  }
};

export const checkOnline = async () => {
  if (!navigator.onLine) return false;

  try {
    return (await fetch(location.origin, {cache: 'no-store'})).ok;
  } catch (e) {
    return false;
  }
};
