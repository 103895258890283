import {SCHEME} from '../constants/scheme';
import {isKakaoTalk} from '../utils/agent';
import {appendQueryParamToUrl} from '../utils/common';
import {executeAppScheme} from '../utils/scheme';

export const closeWebView = () => {
  window.open(SCHEME.CLOSE_WEBVIEW, '_self');
};

export const openExternalWebview = (url: string, {isCurrentWebviewClose}: {isCurrentWebviewClose: boolean}) => {
  if (!isKakaoTalk) {
    window.open(url, '_blank');
    return;
  }

  const schemeUrl = appendQueryParamToUrl(SCHEME.OPEN_WEBVIEW, {
    url,
    ...(isCurrentWebviewClose && {close: true})
  });

  executeAppScheme(schemeUrl);
};

export const fileDownloadOnlyiOS = (fileUrl: string, fileName: string) => {
  const scheme = `${SCHEME.IOS_DOWNLOAD}?url=${fileUrl}&filename=${fileName}`;
  executeAppScheme(scheme);
};
