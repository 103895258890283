import {Service} from '../constants/service';

import {isDev, log} from './common';

const logTiara = (type: string, ...msg: any[]) => {
  if (!isDev) return;
  log(`TIARA ${type}`, ...msg);
};

export type TiaraProp = {
  section: Service;
  pageName?: string;
  pageMeta?: TiaraMeta;
};

export interface TrackInterface {
  action?: string;
  pageName?: string;
  customProps?: {[key: string]: string};
  id?: string;
  name?: string;
}

const SVC_DOMAIN = 'digitalcard.m.app';

export const getTracker = ({section, pageName: initialPageName, pageMeta}: TiaraProp) => {
  const tracker = window.TiaraTracker?.getInstance()
    .init({
      svcDomain: SVC_DOMAIN,
      deployment: isDev ? 'dev' : 'production',
      autoClick: true
    })
    .setSection(section);

  const trackPage = ({action, pageName, customProps}: TrackInterface = {}) => {
    const pageNameValue = pageName ?? initialPageName;
    const pageAction = action ?? initialPageName;

    if (!pageNameValue || !pageAction) {
      logTiara('pageName is not offered');
      return;
    }

    logTiara('trackPage', pageNameValue, pageAction, customProps);
    tracker
      ?.setPage(pageNameValue)
      .trackPage(pageAction)
      .pageMeta(pageMeta || {})
      .customProps(customProps || {})
      .track();
  };

  const trackUsage = (usage: TiaraUsage) => {
    logTiara('trackUsage', pageMeta, usage);

    if (!initialPageName) {
      logTiara('pageName is not offered');
      return;
    }

    tracker
      ?.trackPage(initialPageName)
      .setPage(initialPageName)
      .pageMeta(pageMeta || {})
      .trackUsage()
      .usage(usage)
      .track();
  };

  const trackEvent = ({id, name, pageName, action, customProps}: TrackInterface) => {
    const eventMeta = {id, name};
    const pageNameValue = pageName ?? initialPageName;
    const tiaraAction = name || action;

    if (!pageNameValue || !tiaraAction) {
      logTiara('pageName is not offered');
      return;
    }

    logTiara('track', pageNameValue, tiaraAction, customProps);
    tracker
      ?.setPage(pageNameValue)
      .trackEvent(tiaraAction)
      .pageMeta(pageMeta || {})
      .eventMeta(eventMeta || {})
      .customProps(customProps || {})
      .track();
  };

  return {tracker, trackPage, trackUsage, trackEvent};
};
