import {SCHEME} from '../constants/scheme';
import {isAndroid, isKakaoTalk, isTabletOrMobile} from '../utils/agent';
import {appendQueryParamToUrl} from '../utils/common';
import {executeAppScheme} from '../utils/scheme';

import {isRequiredKakaoTalkVersionForStudent} from './digitalcard';

const ALLOWED_HOST = ['kakao.com', 'daum.net', 'kakaofriends.com'];

/**
 * @param hostname
 * @returns {string}
 * 서브도메인을 제거하는 함수
 * student-id.kakao.com -> kakao.com
 */
const removeSubdomain = (hostname: string): string => {
  const url = hostname.split('.').slice(-2);
  return url.join('.');
};

/**
 * @param origin
 * @returns {boolean}
 * kakaotalk://inappbrowser 스킴을 사용할 수 있는지 체크하는 함수
 */
const isAllowedHostname = (origin: string): boolean => {
  const url = new URL(origin);
  const {hostname} = url;

  return ALLOWED_HOST.includes(removeSubdomain(hostname));
};

/**
 * 안드로이드 > 전용웹뷰에서 kakaotalk://inappbrowser 스킴의 경우 허용된 호스트에서만 정상 동작한다.
 * 허용된 호스트가 아닌 경우 kakaotalk://web/open 스킴을 열어야 한다.
 * https://kakao.agit.in/g/300026604/wall/383300231#comment_panel_386791023
 * @param url
 */
const determineScheme = (url: string) => {
  const scheme =
    isAndroid && isRequiredKakaoTalkVersionForStudent && !isAllowedHostname(url)
      ? SCHEME.OPEN_WEBVIEW
      : SCHEME.INAPPBROWSER;
  return scheme;
};

export const openInappbrowser = (url: string) => {
  if (!isKakaoTalk) {
    // iOS Safari에서 setTimeout으로 래핑해야 동작
    setTimeout(() => window.open(url, '_blank'));
    return;
  }

  const scheme = determineScheme(url);
  const schemeUrl = appendQueryParamToUrl(scheme, {url});
  executeAppScheme(schemeUrl);
};

/**
 * openInappbrowser은 모웹에서 발급웹뷰 랜딩이 불가능하여 추가한 함수
 * 모바일웹에서도 web2app로 실행하여 webview 오픈
 * no_multi는 인앱브라우저 분리 옵션 이슈 처리를 위해 임시 사용
 * https://jira.daumkakao.com/projects/CARD/issues/CARD-416
 * @param url
 */
export const openWebview = (url: string) => {
  if (!isTabletOrMobile) {
    window.open(url, '_blank');
    return;
  }

  const scheme = determineScheme(url);
  const schemeUrl = appendQueryParamToUrl(scheme, {url, no_multi: true});
  executeAppScheme(schemeUrl);
};
