/**
 * 마크업 스크립트 파일을 가져와 사용하는 코드로 텍스트 밑줄을 강조한다.
 * https://github.kakaocorp.com/UIProj2/student-idcard-2023/blob/0870ba724a566aebe4c9e99e72e24f00980090c1/src/js/ui.js#L37
 */
export const transitionEmphline = () => {
  const emphTitleRef = document.querySelector<HTMLElement>('.emph_color');
  const emphlineRef = document.querySelector<HTMLSpanElement>('.emph_line');

  if (!emphlineRef || !emphTitleRef) return;

  const titleWidth = emphTitleRef.clientWidth;
  const lineAnimationSpeed = 500;

  setTimeout(() => {
    emphlineRef.style.width = `${titleWidth}px`;
    emphlineRef.style.transitionDuration = `${titleWidth / lineAnimationSpeed}s`;
  }, 100);
};
