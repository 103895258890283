import lottie from 'lottie-web';

export type LottieId = 'animation_agree' | 'animation_agree1' | 'animation_progress2' | 'animation_progress3';
type CreateLottieRefProps = {
  loop: boolean;
  autoplay: boolean;
  path: string;
};

const LOTTIE_MAPPER: {[id in LottieId]: CreateLottieRefProps} = {
  animation_agree: {
    loop: false,
    autoplay: true,
    path: 'https://t1.kakaocdn.net/zzng_static/digital-card/lottie/complete_agree.json'
  },
  animation_agree1: {
    loop: false,
    autoplay: true,
    path: 'https://t1.kakaocdn.net/zzng_static/digital-card/lottie/complete_agree01.json'
  },
  animation_progress2: {
    loop: true,
    autoplay: true,
    path: 'https://t1.kakaocdn.net/zzng_static/digital-card/lottie/progress_issuance02.json'
  },
  animation_progress3: {
    loop: true,
    autoplay: true,
    path: 'https://t1.kakaocdn.net/zzng_static/digital-card/lottie/progress_issuance03.json'
  }
};

export const createLottie = (id: LottieId) => {
  const container = document.getElementById(id);
  const data = LOTTIE_MAPPER[id];

  if (!container) return;

  lottie.loadAnimation({
    container,
    renderer: 'svg',
    ...data
  });
};
