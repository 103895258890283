import {DARK_MODE_MEDIA_QUERY} from '@zzng/common/constants/mediaQuery';
import {ALL_SCHEME_REG, SCHEME} from '@zzng/common/constants/scheme';
import web2app from '@zzng/common/lib/web2app';
import {openInappbrowser} from '@zzng/common/scheme/inappbrowser';
import {isIOS, isValidTalkVersion, kakaoTalkVersion} from '@zzng/common/utils/agent';
import {appendQueryParamToUrl} from '@zzng/common/utils/common';
import {determineProtocol, executeAppScheme, getIntent} from '@zzng/common/utils/scheme';

// 디지털카드에서 사용하고 있는 서비스 도메인들
const SERVICE_ORIGINS = [ZIKZANG_ORIGIN, STUDENT_ORIGIN, EXCLUSIVE_ORIGIN];

// 디지털카드 2.0 전용웹뷰 최소 버전
export const DIGITALCARD_MINIMUM_TALK_VERSION = '10.0.5';

// 디지털카드 2.0 화이트리스트에 있는 도메인이 아닐 경우 iOS에서는 클라 헤더를 띄워주도록 작업
// https://jira.daumkakao.com/browse/CARD-214
// NOTE: 10.1.0이상 톡학생증 전용웹뷰 최소 버전(10.2.0) 미만인 경우 헤더 없애는 작업 필요, 추후 최소 지원 버전(10.2.0)이 올라가면 대응 필요
export const IOS_HEADER_ISSUE_VERSION = '10.1.0';

// 톡학생증 전용웹뷰 최소 버전
export const STUDENTID_MINIMUM_TALK_VERSION = '10.2.0';

// 톡사원증 전용웹뷰 최소 버전
export const ZIKZANGID_MINIMUM_TALK_VERSION = '10.9.0';

// NODE: 디지털카드 스킴(digitalcard://)을 통해 열었을 때 전용웹뷰인지 인앱브라우저인지 확인하고 싶을 때 사용하는 변수
// ex1. 10.0.5 미만 유저가 채팅방에 disitalcard://open/url?url=https://student-id.kakao.com 입력하여 열면 인앱브라우저가 열림
// ex1. 10.0.5 이상 유저가 채팅방에 disitalcard://open/url?url=https://student-id.kakao.com 입력하여 열면 전용웹뷰가 열림
export const isRequiredKakaoTalkVersionForDigitalCard = isValidTalkVersion(
  kakaoTalkVersion,
  DIGITALCARD_MINIMUM_TALK_VERSION
);

// NOTE: 톡학생증의 origin으로 열었을 때 전용웹뷰로 열리지는지 확인하고 싶을 때 사용하는 변수
// ex. 10.2.0 미만 유저가 student-id.kakao.com 으로 웹뷰를 열었을 때 인앱브라우저로 열림
// ex. 10.2.0 이상 유저가 student-id.kakao.com 으로 웹뷰를 열었을 때 전용웹뷰로 열림
export const isRequiredKakaoTalkVersionForStudent = isValidTalkVersion(
  kakaoTalkVersion,
  STUDENTID_MINIMUM_TALK_VERSION
);

// NOTE: 톡사원증의 origin으로 열었을 때 전용웹뷰로 열리지는지 확인하고 싶을 때 사용하는 변수
// ex. 10.9.0 미만 유저가 employee-id.kakao.com 으로 웹뷰를 열었을 때 인앱브라우저로 열림
// ex. 10.9.0 이상 유저가 employee-id.kakao.com 으로 웹뷰를 열었을 때 전용웹뷰로 열림
export const isRequiredKakaoTalkVersionForZikzang = isValidTalkVersion(
  kakaoTalkVersion,
  ZIKZANGID_MINIMUM_TALK_VERSION
);

/**
 * 안드로이드에서 location.replace()를 실행하여도 히스토리가 남아있는 이슈 때문에 추가된 함수
 * 이 함수를 사용할 경우 브라우저 history가 비워진다. (최소버전 10.0.5)
 * @param url string
 */
export const clearReplace = (url: string) => {
  if (!isValidTalkVersion(kakaoTalkVersion, STUDENTID_MINIMUM_TALK_VERSION) || isIOS) {
    location.replace(url);
  }

  const newUrl = appendQueryParamToUrl(url, {shouldClearHistory: true});
  location.replace(newUrl);
};

/**
 * 상태바 색상을 반환하는 함수
 * 앱은 ARGB, 웹은 RGBA로 반환한다.
 * @returns {Promise<{app: string, web: string}>} 디바이스 별 상태바 색상
 */
export const getStatusBarColor = async () => {
  const appColor = (await window.digitalCard?.getStatusBarColor?.()) ?? '#FFFFFFFF';
  const alpha = appColor.substring(1, 3);
  const rest = appColor.substring(3);
  const webColor = ['#', rest, alpha].join('');
  return {app: appColor, web: webColor};
};

/**
 * 화면 상단의 웹뷰 영역 밖인 safe area를 딤드 처리하기 위한 인터페이스 (최소버전 10.2.0)
 * @param color
 * @param duration
 */
export const changeStatusBarColor = (color = '#999999', duration = 0) => {
  window.digitalCard?.changeStatusBarColor?.(color, duration);

  // IOS 사파리 대응
  const appleMobileWebAppStatusBarStyleMeta = document.querySelector(
    "meta[name='apple-mobile-web-app-status-bar-style']"
  );
  const themeColorMeta = document.querySelector("meta[name='theme-color']");
  [appleMobileWebAppStatusBarStyleMeta, themeColorMeta].forEach((meta) => {
    meta?.setAttribute('content', color);
  });
};

export const resetChangeStatusBarColor = () => {
  // 다크모드 여부에 따라 상태바 색상을 초기화한다.
  const isDarkMode = matchMedia(DARK_MODE_MEDIA_QUERY).matches;
  changeStatusBarColor(isDarkMode ? '#000000' : '#ffffff');
};

export const createOpenDigitalCardScheme = (url: string) => {
  return `${SCHEME.DIGITAL_CARD}?url=${encodeURIComponent(url)}`;
};

export const openDigitalCard = (url: string) => {
  executeAppScheme(createOpenDigitalCardScheme(url));
};

export const openDigitalCardWithGoodsParam = (fullUrl: string, goods: 'WALLPAPER' | 'PPTX_TEMPLATE') => {
  const url = appendQueryParamToUrl(fullUrl, {goods});
  openDigitalCard(url);
};

/*
 * 외부 모바일웹 페이지에서 디지털카드 웹뷰를 실행할 때 사용한다.
 * ios 외부 모바일웹에서 사용자 인터렉션 없이 javascript로 웹뷰를 실행하는 경우 유니버셜링크가 동작하지 않아 scheme을 직접 호출한다.
 * 일부 브라우저는 사용자 인터렉션 없이 스킴 호출이 불가능하기 때문에 스킴이 실행되지 않아도 앱스토어로 연결시키지 않는다.
 */
export const openDigitalCardAtExternal = (url: string) => {
  const scheme = `${SCHEME.DIGITAL_CARD}?url=${encodeURIComponent(url)}`;
  const protocol = determineProtocol();
  const urlScheme = scheme.replace(ALL_SCHEME_REG, protocol);
  const intentURI = getIntent(scheme);

  web2app({
    urlScheme,
    intentURI,
    onAppMissing: () => {}
  });
};

/**
 * kakaotalk://digitalcard/open 스킴으로 들어온 웹뷰를 사용할 경우 DOM을 렌더링 하기 전에 항상 호출해주어야 한다.
 * 만약 호출하지 않으면 타입에러가 발생한다.
 */
export const initClientInterface = () => {
  window.historyBack = () => window.history.back();
  window.handleMobileKeyboardStatus = () => {};
  resetChangeStatusBarColor();
};

/**
 * (최소버전 10.9.0)
 * 기존 웹뷰를 유지한 채 새로운 디지털카드 웹뷰를 열고 싶을 때 사용한다.
 * 그 위에 새로운 디카 웹뷰가 올라오면 아래 웹뷰는 유지하지 않는다.
 * https://jira.daumkakao.com/browse/CARD-493
 * @param url
 */
export const openDigitalCardWithTargetBlank = (url: string) => {
  const urlInstance = new URL(url);
  const {origin} = urlInstance;

  // 허용된 도메인이 아닐 경우 인앱브라우저로 열어준다.
  if (!SERVICE_ORIGINS.includes(origin)) {
    openInappbrowser(url);
    return;
  }

  const scheme = createOpenDigitalCardScheme(url);
  const schemeWithTarget = appendQueryParamToUrl(scheme, {target: '_blank'});
  executeAppScheme(schemeWithTarget);
};
