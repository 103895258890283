import {PHASE} from '@zzng/common/constants/phase';

const createLoadingElement = () => {
  const div = document.createElement('div');

  div.style.position = 'fixed';
  div.style.top = '50%';
  div.style.left = '50%';
  div.style.backgroundColor = '#ffcd00';
  div.style.borderRadius = '75px 75px 75px 75px';
  div.style.height = '50px';
  div.style.width = '50px';
  div.style.transform = 'translate(-50%, -50%)';

  return div;
};

const animateLoadingElement = (div: HTMLDivElement) => {
  div.animate(
    [
      {transform: 'translate(-50%, -50%) scale(1, 1)', opacity: '1'},
      {transform: 'translate(-50%, -50%) scale(1.9, 1.9)', opacity: '.5'}
    ],
    {
      duration: 500,
      easing: 'ease-in-out',
      iterations: 200
    }
  );
};

export const init = (headerClass: string) => {
  if (TARGET === PHASE.LOCAL || TARGET === PHASE.SANDBOX) {
    const headerTitleRef = document.querySelector(headerClass);
    if (!headerTitleRef) {
      console.error('[BUGGERKING ERROR] 헤더의 클래스를 찾을 수 없습니다.');
      return;
    }

    const loadingElement = createLoadingElement();
    let timerId: ReturnType<typeof setInterval>;
    let scriptLoaded = false;

    headerTitleRef.addEventListener(
      'touchstart',
      () => {
        if (scriptLoaded) return;

        document.body.appendChild(loadingElement);
        animateLoadingElement(loadingElement);

        timerId = setTimeout(() => {
          if (scriptLoaded) return;

          document.body.removeChild(loadingElement);

          const scriptRef = document.createElement('script');
          scriptRef.src = 'https://buggerking.kakao.com/sdk/main.bundle.js';
          document.body.appendChild(scriptRef);

          scriptRef.onload = () => {
            window.buggerking.init({active: true});
          };

          scriptLoaded = true;
        }, 2000);
      },
      {passive: true}
    );

    headerTitleRef.addEventListener('touchend', () => {
      clearInterval(timerId);

      if (!scriptLoaded) {
        document.body.removeChild(loadingElement);
      }
    });
  }
};
