/* eslint-disable @typescript-eslint/no-use-before-define */
import {Browser, getAgent, OS} from '@kakao/agent';

const ANDROID_SUPPORT_VERSION = 5.0;
const IOS_SUPPORT_VERSION = 8.0;
const ULINK_SUPPORT_VERSION = 9.0;
const CHROMIUM_INTENT_SUPPORT_VERSION = 25;
const SCHEME_TIME = {ANDROID: 1300, IOS: 1500};
const CLICKED_WAITING_TIME = 2000;

/**
 * 앱이 설치되지 않은 경우 사용할 함수를 지정한다.
 * @param {string} storeURL 앱이 설치되지 않은 경우, 이동할 앱의 설치 주소
 * @return 앱 설치 주소 유무에 따라 설치 주소로 이동하는 함수 또는 알람을 띄우는 함수를 반환한다.
 */
function fallback(storeURL: string | undefined) {
  function moveToStore() {
    // @ts-ignore window.top은 존재해야 함
    window.top.location.href = storeURL as string;
  }

  function alertAppMissing() {
    alert('앱이 설치되지 않았습니다. \n 앱을 설치해주세요');
  }

  return typeof storeURL === 'string' ? moveToStore : alertAppMissing;
}

function unsupportedEnvironment() {
  alert('지원하지 않는 환경입니다.');
}

interface Context {
  urlScheme: string;
  // android에서 사용
  intentURI?: string;
  // iOS 9부터 지원
  universalLink?: string;
  // urlScheme만 사용할지 체크
  useUrlScheme?: boolean;
  // app store URI
  storeURL?: string;
  // 앱을 열기 전에 실행되는 함수
  onAppMissing?: () => void;
  // 앱이 없을 경우 실행되는 함수
  onUnsupportedEnvironment?: () => void;
  // 지원되지 않는 환경에서 실행되는 함수
  willInvokeApp?: () => void;
}

let agent: ReturnType<typeof getAgent> | null = null;

/**
 * URI를 받아 앱 설치 유무를 판단하여 앱을 열거나 스토어로 보낸다.
 * @param {object} context urlScheme, intentURI, storeURL, Ulink, useUrlScheme, onAppMissing, onUnsupportedEnvironment, willInvokeApp
 */
function web2app(context: Context) {
  agent = getAgent();
  const willInvokeApp = typeof context.willInvokeApp === 'function' ? context.willInvokeApp : () => {};
  const onAppMissing = typeof context.onAppMissing === 'function' ? context.onAppMissing : fallback(context.storeURL);
  const onUnsupportedCallback =
    typeof context.onUnsupportedEnvironment === 'function' ? context.onUnsupportedEnvironment : unsupportedEnvironment;

  willInvokeApp();

  if (isSupportedAndroid()) {
    web2appAndroid(context.intentURI, context.urlScheme, context.useUrlScheme, onAppMissing);
  } else if (isSupportedIOS()) {
    web2appIOS(context.universalLink, context.urlScheme, onAppMissing);
  } else {
    onUnsupportedCallback();
  }
}

/**
 * 안드로이드 버전을 확인해 지원여부를 반환힌다.
 * 해당 라이브러리는 안드로이드 5.0 이상을 지원힌다.
 * @return 안드로이드 5.0 이상인 경우 true를 반환한다.
 */
function isSupportedAndroid() {
  const isAndroid = agent?.os === OS.Android;
  const isSupportVersion = agent?.osVersion && parseFloat(agent.osVersion) >= ANDROID_SUPPORT_VERSION;

  // 갤럭시탭 삼성 브라우저에서 device type을 desktop로 인식하고 있어서 예외처리
  const isTabletAndSamsungBrowser = agent?.isDesktop && agent?.browser === Browser.SamsungBrowser;

  return (isAndroid && isSupportVersion) || isTabletAndSamsungBrowser;
}

/**
 * IOS 버전을 확인해 지원여부를 반환한다.
 * 해당 라이브러리는 IOS 8.0 이상을 지원한다.
 * @return IOS 8.0 이상인 경우 true를 반환한다.
 */
function isSupportedIOS() {
  const isIOS = agent?.os === OS.iOS;
  const isSupportVersion = agent?.osVersion && parseFloat(agent.osVersion) >= IOS_SUPPORT_VERSION;

  // 아이패드 사파리에서 os를 mac으로 인식하고 있어서 예외처리
  const isTableAndSafari = agent?.os === OS.Mac && agent?.isTablet;

  return (isIOS && isSupportVersion) || isTableAndSafari;
}

/**
 * Anroid의 경우 URI를 처리한다
 * @param {string} intentURI 앱이 이동할 주소, 앱 미설치시 자동으로 스토어로 이동
 * @param {string} urlScheme 앱이 이동할 주소
 * @param {boolean} useUrlScheme urlScheme을 사용할 것이지 정함
 * @param {function} onAppMissing 앱 미설치시 실행할 함수
 */
function web2appAndroid(
  intentURI: string | undefined,
  urlScheme: string,
  useUrlScheme: boolean | undefined,
  onAppMissing: () => void
) {
  if (isIntentSupportedBrowser() && intentURI && !useUrlScheme) {
    launchAppViaChangingLocation(intentURI);
  } else {
    web2appViaUrlSchemeAnroid(urlScheme, onAppMissing);
  }
}

/**
 * IOS의 경우 URI를 처리한다
 * @param {string} Ulink 앱이 이동할 주소
 * @param {string} urlScheme 앱이 이동할 주소
 * @param {function} onAppMissing 앱 미설치시 실행할 함수
 */
function web2appIOS(Ulink: string | undefined, urlScheme: string, onAppMissing: () => void) {
  const timerID = checkTime(SCHEME_TIME.IOS, onAppMissing);
  bindClearTimer(timerID);

  if (isULinkSupported()) {
    const URI = Ulink || urlScheme;
    launchAppViaChangingLocation(URI);
  } else {
    launchAppViaHiddenIframe(urlScheme);
  }
}

/**
 * 해당 브라우저의 종류와 버전을 확인해 intent 지원여부를 반환한다.
 * https://developer.chrome.com/multidevice/android/intents
 * @return 크로미움(chrome, edge, samsung, opera, whale 등) 25.0 이상인 경우 혹은 firefox인 경우 true를 반환한다.
 */
function isIntentSupportedBrowser() {
  const chromiumVersion = /Chrome\/([\d.]+)/.exec(navigator.userAgent)?.[1];
  const isValidChromiumVersion = chromiumVersion && parseFloat(chromiumVersion) >= CHROMIUM_INTENT_SUPPORT_VERSION;
  const isFirefox = /Firefox/.test(navigator.userAgent);
  return isValidChromiumVersion || isFirefox;
}

/**
 * 해당 브라우저의 종류와 버전을 확인해 Universal link 지원여부를 반환한다.
 * https://developer.apple.com/ios/universal-links/
 * @return IOS 9.0 이상인 경우 true를 반환한다.
 */
function isULinkSupported() {
  const isSupportVersion = agent?.osVersion && parseFloat(agent.osVersion) >= ULINK_SUPPORT_VERSION;
  return isSupportVersion;
}

/*
 * window.top은 최상위 window를 가리킴
 * 자식 window에서 앱을 여는 등의 액션에서 문제가 있을 여지가 있음
 */
function launchAppViaChangingLocation(uri: string) {
  // @ts-ignore window.top은 존재해야 함
  window.top.location.href = uri;
}

/**
 * 앱 설치 여부를 판단하여 iframe을 이용해 페이지를 이동하거나 onAppMissing을 실행한다.
 * @description
 * iframe의 경우 구형 브라우저에서만 작동하기 때문에
 * chrome ver.25 이상에서는 href를 이용해 앱을 실행한다.
 * window.open으로 여는 경우 스킴이 잘못되었을때 fallback이 실행되지 않는다.
 * @param {string} urlScheme 이동할 urlScheme
 * @param {function} onAppMissing 앱 미설치시 사용할 함수
 */
function web2appViaUrlSchemeAnroid(urlScheme: string, onAppMissing: () => void) {
  const timerID = checkTime(SCHEME_TIME.ANDROID, onAppMissing);
  bindClearTimer(timerID);

  if (isNeedIframe()) {
    launchAppViaHiddenIframe(urlScheme);
  } else {
    launchAppViaChangingLocation(urlScheme);
  }
}

/**
 * 유효시간 내에 기존 페이지에 있으면 앱이 설치되지 않았다 판단하고 onAppMissing을 실행한다.
 * @description
 * timeout의 경우 앱이 열리기까지 기다리는 시간으로
 * android는 1300ms, ios는 1500ms이다.
 * 이는 실험을 통해 얻어진 수치로 버전이 올라가면 확인이 필요하다.
 * @param {number} timeout 앱이 열리기까지 기다리는 시간
 * @param {function} onAppMissing 앱 미설치시 사용할 함수
 * @return 타이머의 id를 반환한다
 */
function checkTime(timeout: number, onAppMissing: () => void) {
  // 유효시간 체크
  const clickedAt = new Date().getTime();
  return setTimeout(() => {
    const now = new Date().getTime();
    if (isPageVisible() && now - clickedAt < CLICKED_WAITING_TIME) {
      onAppMissing();
    }
  }, timeout);
}

/**
 * 웹 페이지가 visible 또는 focus 상태인지 확인한다.
 * https://developer.mozilla.org/ko/docs/Web/API/Page_Visibility_API
 * @return 웹 페이지가 보이면 true를 반환한다.
 */
function isPageVisible() {
  const doc: Document & {
    msHidden?: boolean;
    webkitHidden?: boolean;
  } = document;
  if (typeof doc.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return !doc.hidden;
  }
  if (typeof doc.msHidden !== 'undefined') {
    return !doc.msHidden;
  }
  if (typeof doc.webkitHidden !== 'undefined') {
    return !doc.webkitHidden;
  }
  // Page Visibility API를 지원하지 않는 브라우저
  return true;
}

/**
 * 앱이 열린 경우, 기존 페이지의 타이머를 지운다
 * https://developer.mozilla.org/ko/docs/Web/API/Page_Visibility_API
 * @param {number} timerID 지울 타이머 ID
 */
function bindClearTimer(timerID: ReturnType<typeof setTimeout>) {
  document.addEventListener('visibilitychange', function clear() {
    if (!isPageVisible()) {
      clearTimeout(timerID);
      document.removeEventListener('visibilitychange', clear);
    }
  });
}

/**
 * iframe이 필요한지 여부를 반환한다.
 * chrome 25 이상부터는 iframe의 src을 통해 url scheme을 여는 것을 지원하지 않는다.
 * https://developer.chrome.com/multidevice/android/intents
 * @return chrome이 아니거나 chrome 25 미만이면 true를 반환한다.
 */
function isNeedIframe() {
  return !isIntentSupportedBrowser();
}

/**
 * iframe의 src 속성을 이용해 앱을 연다.
 * @param {string} urlScheme 이동할 urlScheme
 */
function launchAppViaHiddenIframe(urlScheme: string) {
  const iframe = createHiddenIframe();
  iframe.src = urlScheme;
}

/**
 * 보이지 않는 iframe을 만든다.
 * https://developer.mozilla.org/ko/docs/Web/HTML/Element/iframe
 * @return 보이지 않는 iframe을 반환한다.
 */
function createHiddenIframe() {
  const iframe = document.createElement('iframe');
  iframe.style.border = 'none';
  iframe.style.width = '0';
  iframe.style.height = '0';
  iframe.style.display = 'none';
  iframe.style.overflow = 'hidden';
  document.body.appendChild(iframe);
  return iframe;
}

export default web2app;
