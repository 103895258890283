import * as Sentry from '@sentry/browser';

import {isCbt, isReal} from './common';

export const initSentry = ({
  dsn,
  origin,
  enabled = isCbt || isReal
}: {
  dsn: string;
  origin: string;
  enabled?: boolean;
}) => {
  if (enabled) {
    Sentry.init({dsn});
    Sentry.setTags({origin});
  }
};

export const convertSentryErrorLevel = (errorResponseStatus?: number) => {
  if (!errorResponseStatus) return;

  // 500 미만 에러는 warning으로 처리하여 알람이 울리지 않도록 한다.
  if (errorResponseStatus < 500) {
    Sentry.configureScope((scope) => {
      scope.setLevel(Sentry.Severity.Warning);
    });
  }
};

export const captureException = (message: string) => {
  Sentry.captureException(new Error(message));
};
