import web2app from '@zzng/common/lib/web2app';

import {
  ALL_SCHEME_REG,
  ANDROID_INTENT_PACKAGE,
  KAKAOTALK_APP_STORE_URL,
  SCHEME_PROTOCOL,
  UNIVERSAL_LINK_BASE_URL
} from '../constants/scheme';

import {isAndroid, isIOS, isKakaoTalk} from './agent';
import {isDev} from './common';

export const determineProtocol = (condition = isIOS && isDev) => {
  const protocol = condition ? SCHEME_PROTOCOL.DEVELOPMENT : SCHEME_PROTOCOL.PRODUCTION;
  return protocol;
};

export const getIntent = (scheme: string) => {
  const intentPackage = ANDROID_INTENT_PACKAGE[TARGET];
  const [, path] = scheme.split('://');

  return `intent://${path}#Intent;package=${intentPackage};scheme=kakaotalk;end;`;
};

export const executeAppSchemeIOS = (scheme: string) => {
  const universalBaseUrl = UNIVERSAL_LINK_BASE_URL[TARGET];
  const universalLink = `${universalBaseUrl}/scheme/${encodeURIComponent(scheme)}`;

  web2app({
    urlScheme: scheme,
    universalLink,
    onAppMissing: () => {}
  });
};

export const executeAppSchemeAndroid = (scheme: string) => {
  const intentURI = getIntent(scheme);

  web2app({
    urlScheme: scheme,
    intentURI,
    storeURL: KAKAOTALK_APP_STORE_URL.AOS
  });
};

export const executeAppScheme = (scheme: string, protocolCondition?: boolean) => {
  const protocol = determineProtocol(protocolCondition);
  const customScheme = scheme.replace(ALL_SCHEME_REG, protocol);

  if (isKakaoTalk) {
    window.open(customScheme, '_self');
    return;
  }

  if (isIOS) {
    executeAppSchemeIOS(customScheme);
    return;
  }

  executeAppSchemeAndroid(customScheme);
};

export const goKakaotalkAppStore = () => {
  window.location.href = isAndroid ? KAKAOTALK_APP_STORE_URL.AOS : KAKAOTALK_APP_STORE_URL.IOS;
};

export const isKakaotalkProtocol = (url: string) => {
  const pattern = /(?:kakaotalk:\/\/|alphatalk:\/\/)/;
  return pattern.test(url);
};
