/** 초성 목록 */
export const HANGUEL_FIRST_CONSONANTS = [
  'ㄱ',
  'ㄴ',
  'ㄷ',
  'ㄹ',
  'ㅁ',
  'ㅂ',
  'ㅅ',
  'ㅇ',
  'ㅈ',
  'ㅊ',
  'ㅋ',
  'ㅌ',
  'ㅍ',
  'ㅎ'
] as const;

/** 초성 타입 */
export type HangeulFirstConsonant = (typeof HANGUEL_FIRST_CONSONANTS)[number];

/** 각 초성에 따른 한글 범위 목록 */
const HANGEUL_CHARACTER_RANGE_FOR_FIRST_CONSONANTS: Array<[HangeulFirstConsonant, [string, string]]> = [
  ['ㄱ', ['가', '깋']],
  ['ㄴ', ['나', '닣']],
  ['ㄷ', ['다', '딯']],
  ['ㄹ', ['라', '맇']],
  ['ㅁ', ['마', '밓']],
  ['ㅂ', ['바', '빟']],
  ['ㅅ', ['사', '싷']],
  ['ㅇ', ['아', '잏']],
  ['ㅈ', ['자', '짛']],
  ['ㅊ', ['차', '칳']],
  ['ㅋ', ['카', '킿']],
  ['ㅌ', ['타', '팋']],
  ['ㅍ', ['파', '핗']],
  ['ㅎ', ['하', '힣']]
];

/** 각 초성에 따른 한글 charcode 목록 */
const HANGEUL_CHARACODE_RANGE_FOR_FIRST_CONSONANTS: Array<[HangeulFirstConsonant, [number, number]]> =
  HANGEUL_CHARACTER_RANGE_FOR_FIRST_CONSONANTS.map(([firstConsonant, [start, end]]) => [
    firstConsonant,
    [start.charCodeAt(0), end.charCodeAt(0)]
  ]);

/** 각 초성에 따른 한글 charcode 범위 */
const HANGEUL_CHARACODE_RANGE_MAP_FOR_FIRST_CONSONANTS = Object.fromEntries(
  HANGEUL_CHARACODE_RANGE_FOR_FIRST_CONSONANTS
) as Record<HangeulFirstConsonant, [number, number]>;

/**
 * 문자열이 해당 초성 인덱스에 포함되는지 여부
 * @param firstConsonant 초성
 * @param target 문자열
 * @example matchHangeulFirstConsonant('ㅅ', '서울대학교') // true
 */
export const matchHangeulFirstConsonant = (firstConsonant: HangeulFirstConsonant, target: string): boolean => {
  const [startCharCode, endCharCode] = HANGEUL_CHARACODE_RANGE_MAP_FOR_FIRST_CONSONANTS[firstConsonant];
  const targetCharCode = target.charCodeAt(0);

  return startCharCode <= targetCharCode && targetCharCode <= endCharCode;
};

const KR_STRING_START_NUM = 44032; // "가"의 char code
const KR_STRING_CONSONANT_CYCLE_NUM = 28; // 받침이 없는 글자 개수

export const isEndWithConsonant = (korStr = '') => {
  const finalChrCode = korStr.charCodeAt(korStr.length - 1);
  // 0 = 받침 없음, 그 외 = 받침 있음
  const finalConsonantCode = (finalChrCode - KR_STRING_START_NUM) % KR_STRING_CONSONANT_CYCLE_NUM;
  return finalConsonantCode !== 0;
};

// 을/를 가져오기
export const getEulReul = (krString = '') => {
  return isEndWithConsonant(krString) ? '을' : '를';
};

// 을/를 덧붙히기
export const appendEulReulToKrString = (krString = '') => {
  return `${krString}${getEulReul(krString)}`;
};

// 이/가 덧붙히기
export const appendIGaToKrString = (krString = '') => {
  return `${krString}${isEndWithConsonant(krString) ? '이' : '가'}`;
};

// 이/가 추출하기
export const extractIGaFromKrString = (krString = '') => {
  return isEndWithConsonant(krString) ? '이' : '가';
};

// 은/는 덧붙히기
export const appendEunNunToKrString = (krString = '') => {
  return `${krString}${isEndWithConsonant(krString) ? '은' : '는'}`;
};
