/**
 * 마크업 스크립트 파일을 가져와 사용하는 코드로 fixed_btm 버튼에 패딩을 추가한다.
 * https://github.kakaocorp.com/UIProj2/student-idcard-2023/blob/0870ba724a566aebe4c9e99e72e24f00980090c1/src/js/ui.js#L45
 */
export const addPaddingBtmOnFixedBtm = () => {
  const contentEl: HTMLDivElement | null = document.querySelector('.content-article');
  const fixedBtm = document.querySelector('.content-article > .fixed_btm');
  const isIntroContainer = document.querySelectorAll('.container-intro').length;

  if (!fixedBtm || !contentEl || isIntroContainer) return;

  const {clientHeight} = fixedBtm;
  const headerSafeArea = 16;
  const footerSafeArea = 16;

  contentEl.style.paddingBottom = `${clientHeight}px`;
  contentEl.style.scrollPaddingBottom = `${clientHeight + headerSafeArea + footerSafeArea}px`;
};
