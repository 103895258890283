export const SCHEME = {
  DIGITAL_CARD: 'kakaotalk://digitalcard/open', // ?url={url}
  OPEN_WEBVIEW: 'kakaotalk://web/open', // ?url={url}
  CLOSE_WEBVIEW: 'kakaotalk://web/close',
  SIGN: 'kakaotalk://me/cert/sign',
  CARD_DETAIL: 'kakaotalk://me/card/home', // ?id={cardId}
  ACCOUNT: 'kakaotalk://account',
  WALLET: 'kakaotalk://me/wallethome', // ?tab={tabName}
  IOS_DOWNLOAD: 'kakaotalk://web/download', // ?url={fileUrl}&filename={filename}
  INAPPBROWSER: 'kakaotalk://inappbrowser', // ?url={url}
  CARD_LIST: 'kakaotalk://con/list?type=idcard',
  DIGITAL_DOCS: 'kakaotalk://digitaldocs/open' // ?url={url}
} as const;

export const ALL_SCHEME_REG = /(kakao|alpha)talk/g;

export const SCHEME_PROTOCOL = {
  DEVELOPMENT: 'alphatalk',
  PRODUCTION: 'kakaotalk'
} as const;

export const UNIVERSAL_LINK_BASE_URL = {
  local: 'https://sandbox-talk-apps.kakao.com',
  sandbox: 'https://sandbox-talk-apps.kakao.com',
  cbt: 'https://talk-apps.kakao.com',
  real: 'https://talk-apps.kakao.com'
} as const;

export const ANDROID_INTENT_PACKAGE = {
  local: 'com.kakao.talk.sandbox',
  sandbox: 'com.kakao.talk.sandbox',
  cbt: 'com.kakao.talk',
  real: 'com.kakao.talk'
} as const;

export const KAKAOTALK_APP_STORE_URL = {
  IOS: 'https://apps.apple.com/kr/app/%EC%B9%B4%EC%B9%B4%EC%98%A4%ED%86%A1-kakaotalk/id362057947',
  AOS: 'https://play.google.com/store/apps/details?id=com.kakao.talk'
} as const;

export const DIGITAL_CARD = {
  EVENT_BENEFIT: `${EXCLUSIVE_ORIGIN}/event-benefit`,
  PRESENT_HISTORY: `${EXCLUSIVE_ORIGIN}/present/history`
};
