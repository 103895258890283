// Note: 디지털카드 맞춤형 서비스를 위한 개인정보 이용 동의 페이지
// common에는 react 컴포넌트 의존성을 가지지 않기 위해 html을 string으로 정의하고 있습니다.
export const __html = `
<h1 class="tit_g type4">
  <span class="item_txt" role="text">
    <span class="txt_tit">디지털카드 맞춤형 서비스를 위한 개인정보 이용 동의</span>
  </span>
</h1>
<p class="txt_g type2">
  (주)카카오는 개인정보를 안전하게 처리하는데 최선을 다합니다.
  <br />
  디지털카드 서비스에서 제공하는 맞춤형 서비스를 이용하기 위해서는 아래 내용에 대해 동의가 필요합니다.
</p>
<h2 class="tit_g type6">
  <span class="item_txt" role="text">
    <span class="txt_tit">[선택] 맞춤형 서비스를 위한 개인정보 이용 동의</span>
  </span>
</h2>
<div class="group_tbl type2">
  <table class="tbl_g tbl_g3">
    <caption class="ir_caption">[선택] 맞춤형 서비스를 위한 개인정보 이용 동의</caption>
    <colgroup>
      <col style={{width: '30%'}} />
      <col style={{width: '40%'}} />
      <col style={{width: '30%'}} />
    </colgroup>
    <thead>
      <tr>
        <th scope="col">목적</th>
        <th scope="col">항목</th>
        <th scope="col">보유기간</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <em class="emph_text">디지털카드 맞춤형 콘텐츠 추천 및 광고에 활용</em>
          <em class="txt_footnote">(1)</em>
        </td>
        <td>
          디지털카드 내 카드 소유자 정보 및 카드 이용 내역
          <ul class="list_policy" role="list">
            <li role="listitem">디지털 카드 발급처 및 이용자가 입력한 정보</li>
            <li role="listitem">디지털 카드 기본 정보(파트너명, 카드명, 상품 정보, 카드 유효값 등)</li>
            <li role="listitem">디지털 카드 이용 내역</li>
          </ul>
        </td>
        <td>
          <em class="emph_text">회원탈퇴 또는 동의철회시 지체 없이 파기</em>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<p class="txt_g type2">
  위 동의를 거부 할 권리가 있으며, 동의를 거부하실 경우 위 목적의 서비스 이용이 제한될 수 있습니다.
</p>
<p class="txt_g type2">
  <em class="txt_footnote">(1)</em> 맞춤형 콘텐츠 추천 및 마케팅 활용 목적으로 이용자 정보와 ‘쿠키’또는
  ‘광고식별자’ 기반으로 수집된 행태정보를 활용할 수 있습니다.
</p>
<p class="txt_g type2"> 디지털카드에 대한 맞춤형 콘텐츠 추천 및 마케팅 활용에 대한 동의 철회는 카카오톡 〉 설정 〉 카카오계정 〉 연결된 서비스 관리 〉 카카오 서비스 〉 디지털카드에서 해주시기 바랍니다. </p>
<p class="txt_g type2"> 더 자세한 내용에 대해서는 카카오 <a href="#none" class="txt_blue" id='terms_privacy' data-url="https://www.kakao.com/policy/privacy?lang=ko">개인정보처리방침</a>을 참고하시기 바랍니다.</p>
`;
